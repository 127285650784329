import {environment} from '../../environments/environment';

// tslint:disable-next-line:variable-name
let _window;
export function track(type, payload) {
    // if (!_window) {_window = (window as any).track('init', isMobile() ? environment.tracking_id_mobile : environment.tracking_id_web); }
    // (window as any).track(type, payload);
}

export function isMobile() {
    try { document.createEvent('TouchEvent'); return true; } catch (e) { return false; }
}
